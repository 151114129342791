import React from "react";
import { Navbar, Nav, NavDropdown, NavLink } from "react-bootstrap";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import logo from "../../assets/images/logo.png";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getLogoutAction } from "../../actions/authActions";

const MainNavbar = (props) => {
  const { auth } = props;
  return (
    <Navbar fixed="top" bg="white" expand="lg" className="shadow">
      <Navbar.Brand>
        <Nav.Link href="/">
          <img
            src={logo}
            height="40"
            className="d-inline-block align-top"
            alt="logo"
          />
        </Nav.Link>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          <Nav.Link className="nav-link" href="/">
            Inicio
          </Nav.Link>
          <HashLink className="nav-link" to="/#agenda">
            Talleres
          </HashLink>
          <Link className="nav-link" to="/store">
            Tienda
          </Link>
          {/* <Link className="nav-link">Retiros</Link> */}
          <HashLink className="nav-link" to="/#camino">
            Mi Camino
          </HashLink>
          <Link className="nav-link" to="/membersite">
            Membresía
          </Link>
          {auth.uid && (
            <Link className="nav-link" to="/membersite/trainee">
              Voluntariado
            </Link>
          )}
          {auth.uid && (
            <Link className="nav-link" to="/membersite/practicas">
              Prácticas
            </Link>
          )}
          {auth.uid ? null : (
            <Link className="nav-link" to="/membersite/login">
              Iniciar Sesion
            </Link>
          )}
          {auth.uid ? (
            <Link className="nav-link" to="/membersite/user">
              Clases
            </Link>
          ) : null}

          {auth.uid ? (
            <NavDropdown title="Biblioteca" id="basic-nav-dropdown">
              <Link
                className="dropdown-item nav-link"
                to="/membersite/library/2020"
              >
                2020
              </Link>
              <Link
                className="dropdown-item nav-link"
                to="/membersite/library/2021"
              >
                2021
              </Link>
              <Link
                className="dropdown-item nav-link"
                to="/membersite/library/2022"
              >
                2022
              </Link>
              <Link
                className="dropdown-item nav-link"
                to="/membersite/library/2023"
              >
                2023
              </Link>
              <Link
                className="dropdown-item nav-link"
                to="/membersite/library/2024"
              >
                2024
              </Link>
            </NavDropdown>
          ) : null}
          {auth.uid ? (
            <NavDropdown title="Mi Cuenta" id="basic-nav-dropdown">
              <Link className="dropdown-item nav-link" to="/membersite/profile">
                Perfil
              </Link>

              <NavLink
                className="dropdown-item nav-link"
                onClick={props.logout}
              >
                Cerrar Sesion
              </NavLink>
            </NavDropdown>
          ) : null}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.firebaseState.auth,
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logout: () => getLogoutAction(),
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(MainNavbar);
